<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid style="max-width: 1600px">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search
              <span style="padding-left: 2px">({{ totalRecord }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.loadNumber"
                    type="text"
                    label="Load Number"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.checkId"
                    type="text"
                    label="Check Id"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.name"
                    type="text"
                    label="Name"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="searchParam.status"
                    :items="paymentStatusAll"
                    item-text="name"
                    item-value="id"
                    label="Status"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="searchParam.paymentMethod"
                    :items="paymentMethodAll"
                    item-text="name"
                    item-value="value"
                    label="Payment Method"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="searchParam.paymentType"
                    :items="paymentTypeAll"
                    item-text="name"
                    item-value="value"
                    label="Payment Type"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <carrier-auto-complete
                    carrier-text="Carrier"
                    class="pt-3"
                    @event="selectedCarrier"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.invoiceNumber"
                    type="text"
                    label="Invoice Number"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.checkNumber"
                    type="text"
                    label="Check Number"
                    @keydown.enter="searchAllPayment()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-btn
                    class="blue white--text dark-3"
                    small
                    @click="searchAllPayment()"
                  >
                    <v-icon small> mdi-search-web </v-icon>
                    Filter
                  </v-btn>
                  <v-btn class="ml-1" small @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-container fluid class="pt-0 data-container">
        <v-data-table
          :headers="headers"
          :items="payments"
          item-key="checkId"
          class="elevation-2 data-table"
          calculate-widths
          :loading="loadingIcon"
          fixed-header
          hide-default-footer
          :items-per-page="pageSize"
        >
          <template v-slot:item.recipient="{ item }">
            <p v-if="item.recipient" class="mb-0">
              <span v-if="item.recipient.line_1">{{
                item.recipient.line_1
              }}</span>
              <span v-else> {{ item.recipient }}</span>
            </p>
            <p v-if="item.recipient.city" class="mb-0">
              <span
                >{{ item.recipient.city }},
                {{ item.recipient.state }}
                {{ item.recipient.zip }}</span
              >
            </p>
          </template>
          <template v-slot:item.carrierName="{ item }">
            <router-link
              :to="{
                name: 'Carrier Detail',
                params: {
                  id: item.carrier.id,
                  title: item.carrier.name + ' - ' + item.carrier.mc_number,
                },
              }"
              class="name-link"
              target="_blank"
            >
              <span style="color: #464646">{{ item.carrierName }}</span>
            </router-link>
          </template>
          <template v-slot:item.payment="{ item }">
            <p class="mb-0">
              <b>{{ item.paymentMethod }} - {{ item.paymentType }}</b>
            </p>
            <p v-if="item.checkId" class="mb-0">
              <b>Id: </b>
              <span>{{ item.checkId }}</span>
            </p>
          </template>
          <template v-slot:item.loadId="{ item }">
            <v-btn
              text
              small
              target="_blank"
              :to="{
                name: 'Load Detail',
                params: { id: item.loadId },
              }"
            >
              {{ item.loadId }}
            </v-btn>
          </template>
          <template v-slot:item.paymentAction="{ item }">
            <!--status 10 means Approved-->
            <v-btn
              v-if="item.status == 10"
              small
              class="font-weight-bold red darken-1"
              @click="
                confirmVoidCheck(
                  item.checkId,
                  item.loadId,
                  item.id,
                  item.amount
                )
              "
            >
              Void
            </v-btn>
          </template>
          <template v-slot:footer>
            <v-page
              :total-row="totalRecord"
              :page-size-menu="[10, 20, 50, 100]"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-col>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Void Check </v-card-title>
        <v-card-text>
          <p>
            Please confirm that you would like to void check
            {{ checkNumber }}.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="orange darken-1" text @click="voidCheck()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex"
import CarrierAutoComplete from "@/views/pages/components/CarrierAutoComplete"
export default {
  name: "ApprovedPayments",
  components: {
    CarrierAutoComplete,
  },
  data() {
    return {
      dialog: false,
      totalRecord: 0,
      searchParam: {
        loadNumber: null,
        checkId: null,
        carrierId: null,
        name: null,
        status: null,
        paymentType: null,
        invoiceNumber: null,
        checkNumber: null,
      },
      payments: [],
      paymentStatusAll: [
        {
          id: 1,
          name: "Paid",
        },
        {
          id: 2,
          name: "In Process",
        },
        {
          id: 4,
          name: "Void",
        },
        {
          id: 5,
          name: "Expired",
        },
        {
          id: 6,
          name: "Printed",
        },
        {
          id: 7,
          name: "Mailed",
        },
        {
          id: 8,
          name: "Failed",
        },
        {
          id: 9,
          name: "Refunded",
        },
      ],
      paymentMethodAll: [
        {
          value: "ACH",
          name: "ACH",
        },
        {
          value: "CHECK",
          name: "Check",
        },
        {
          value: "FACTORING",
          name: "Factoring",
        },
      ],
      paymentTypeAll: [
        {
          value: "QUICK_PAY",
          name: "Quick Pay",
        },
        {
          value: "STANDARD_PAY",
          name: "Net 30",
        },
      ],
      headers: [
        {
          text: "Paid On",
          align: "center",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Load #",
          align: "center",
          sortable: false,
          value: "loadId",
        },
        {
          text: "Carrier",
          align: "center",
          sortable: false,
          value: "carrierName",
        },
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Recipient",
          align: "center",
          sortable: false,
          value: "recipient",
        },
        {
          text: "Amount",
          align: "right",
          sortable: false,
          value: "amount",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "statusDisplay",
        },
        {
          text: "Payment",
          align: "center",
          sortable: false,
          value: "payment",
        },
        {
          text: "Invoice #",
          align: "center",
          sortable: false,
          value: "invoiceNumber",
        },
        {
          text: "Check #",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "paymentAction",
        },
      ],
      queryParams: {},
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
      loadId: null,
      checkId: null,
      checkNumber: null,
      checkAmount: null,
      approveCheckIds: [],
      approveAllBtn: true,
    }
  },
  computed: {
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
    }),
    ...mapGetters("billing", {
      approvedPaymentList: "approvedPaymentList",
    }),
  },
  watch: {
    approvedPaymentList() {
      this.totalRecord = this.approvedPaymentList.count
      this.payments = this.approvedPaymentList.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.receivableLoads = []
    this.getLoadPayments()
  },
  methods: {
    getLoadPayments() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.queryParams.ordering = "-created_at"
      this.$store.dispatch("billing/GET_APPROVED_PAYMENTS", this.queryParams)
    },
    resetSearch() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.searchParam.loadNumber = null
      this.searchParam.checkId = null
      this.searchParam.carrierId = null
      this.searchParam.name = null
      this.searchParam.status = null
      this.searchParam.paymentMethod = null
      this.searchParam.paymentType = null
      this.searchParam.invoiceNumber = null
      this.searchParam.checkNumber = null
      this.queryParams.ordering = "-created_at"
      this.$store.commit("generic/setCarrierId", null)
      this.$store.dispatch("billing/GET_APPROVED_PAYMENTS", this.queryParams)
    },
    searchAllPayment() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.queryParams.ordering = "-created_at"
      if (this.searchParam.loadNumber) {
        this.queryParams.load_id = this.searchParam.loadNumber
      }
      if (this.searchParam.checkId) {
        this.queryParams.check_id__icontains = this.searchParam.checkId
      }
      if (this.searchParam.carrierId) {
        this.queryParams.carrier_id = this.searchParam.carrierId
      }
      if (this.searchParam.name) {
        this.queryParams.name__icontains = this.searchParam.name
      }
      if (this.searchParam.status) {
        this.queryParams.status = this.searchParam.status
      }
      if (this.searchParam.paymentMethod) {
        this.queryParams.payment_method = this.searchParam.paymentMethod
      }
      if (this.searchParam.paymentType) {
        this.queryParams.payment_type = this.searchParam.paymentType
      }
      if (this.searchParam.invoiceNumber) {
        this.queryParams.invoice_number__icontains =
          this.searchParam.invoiceNumber
      }
      if (this.searchParam.checkNumber) {
        this.queryParams.id = this.searchParam.checkNumber
      }
      this.$store.dispatch("billing/GET_APPROVED_PAYMENTS", this.queryParams)
    },
    readDataFromAPI(value) {
      if (this.payments.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch("billing/GET_APPROVED_PAYMENTS", this.queryParams)
      }
    },
    selectedCarrier(value) {
      if (value.carrier) {
        if (value.flag === 1) {
          this.searchParam.carrierId = value.carrier.id
          this.searchAllPayment()
        } else {
          this.searchParam.carrierId = value.carrier.id
        }
      }
    },
    confirmVoidCheck(checkId, loadId, checkNumber, amount) {
      this.checkId = checkId
      this.loadId = loadId
      this.checkNumber = checkNumber
      this.checkAmount = amount
      this.dialog = true
    },
    voidCheck() {
      const payload = {
        load_id: this.loadId,
        check_id: this.checkId,
      }
      this.$store
        .dispatch("billing/POST_CHECK_VOID", payload)
        .then(() => {
          this.$store.dispatch(
            "billing/GET_APPROVED_PAYMENTS",
            this.queryParams
          )
          this.dialog = false
        })
        .catch((e) => {
          this.dialog = false
        })
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
